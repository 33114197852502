<style>
.docBox {
  width: 300px;
  height: 300px;
  margin-right: 10px;
  border: 1px solid #073763;
  overflow: hidden;
}
.docContainer {
  display: flex;
  flex-direction: row;
}
</style>
<template>
  <div id="main-wrapper">
    <div class="content-container profile-page personal-details">
      <div class="page-wrap">
        <div class="page-content clearfix">
          <div class="main-content f-left">
            <div class="content-holder wow fadeInLeft">
              <div class="row-sec">
                <div class="nav-holder clearfix">
                  <button
                    @click="tab = 1"
                    class="btn f-left"
                    :class="tab == 1 ? 'btn-yellow' : 'btn-blue'"
                  >
                    Personal Details
                  </button>
                  <button
                    @click="tab = 2"
                    class="btn f-right"
                    :class="tab == 2 ? 'btn-yellow' : 'btn-blue'"
                  >
                    Uploaded Documents
                  </button>
                </div>
                <div v-if="tab == 1" class="form-holder">
                  <ul class="clearfix">
                    <li class="half-width f-left">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.firstname"
                        placeholder="First Name"
                        disabled
                      />
                    </li>
                    <li class="half-width f-right">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.lastname"
                        placeholder="Last Name"
                        disabled
                      />
                    </li>
                    <li class="half-width f-left">
                      <input
                        type="text"
                        name=""
                        :value="COMMON.formatDate(store.profile.dob)"
                        placeholder="Date of Birth"
                        disabled
                      />
                    </li>
                    <li class="half-width f-right">
                      <input
                        type="text"
                        name=""
                        :value="(store.profile.gender = 'M') ? 'Male':(store.profile.gender == 'F') ? 'Female':'-'"
                        placeholder="Gender"
                        disabled
                      />
                    </li>
                    <li class="half-width f-left">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.mobile_no"
                        placeholder="Phone Number"
                        disabled
                      />
                    </li>
                    <li class="half-width f-right">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.email"
                        placeholder="Email"
                        disabled
                      />
                    </li>
                    <li class="half-width f-left">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.address1"
                        placeholder="Address"
                        disabled
                      />
                    </li>
                    <li class="half-width f-right">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.city"
                        placeholder="City"
                        disabled
                      />
                    </li>
                    <li class="half-width f-left">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.residence_country"
                        placeholder="Country"
                        disabled
                      />
                    </li>
                    <li class="half-width f-right">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.post_code"
                        placeholder="Zip Code"
                        disabled
                      />
                    </li>
                    <!-- <li class="half-width f-left">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.mobile_no"
                        placeholder="Account Class"
                      />
                    </li> -->
                    <li class="half-width f-left">
                      <input
                        type="text"
                        name=""
                        :value="store.profile.P_IDNUMBER"
                        placeholder="ID Number"
                        disabled
                      />
                    </li>
                  </ul>
                  <!-- <div class="btn-holder t-center">
                    <button
                      onclick="history.back()"
                      class="btn btn-lblue btn-back"
                    >
                      Back
                    </button>
                    <a
                      href="#profile-confirm"
                      class="btn btn-blue popup-link-holder"
                      >Update Profile</a
                    >
                  </div> -->
                </div>
                <div v-else class="form-holder">
                  <ul
                    v-if="
                      store.profile.documents && store.profile.documents.length
                    "
                    class="clearfix docContainer"
                  >
                    <a
                      class="docBox"
                      :href="`${url}/get/docs?type=user&id=${store.user.profile.user_id}&doc=${image.document}`"
                      target="_blank"
                      v-for="image of store.profile.documents"
                      :key="image.document"
                    >
                      <vue-pdf-embed
                        v-if="image.extension == 'application/pdf'"
                        :source="`${url}/get/docs?type=user&id=${store.user.profile.user_id}&doc=${image.document}`"
                      />
                      <img
                        v-else
                        :src="`${url}/get/docs?type=user&id=${store.user.profile.user_id}&doc=${image.document}`"
                      />
                    </a>
                  </ul>
                  <p v-else>No Documents Yet</p>
                </div>
                <!-- <div class="btn-holder t-center">
                <button  class="btn btn-lblue btn-back">Back</button>
                <a href="#" class="btn btn-blue popup-link-holder">Update Profile</a>
              </div> -->
              </div>
            </div>
            <div class="img-holder t-center wow fadeInRight">
              <img src="assets/images/personal-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  components: { VuePdfEmbed },
  data() {
    return {
      tab: 1,
      url: process.env.VUE_APP_API_BASE_URL,
    };
  },
  mounted() {
    if (!this.store.profile.firstname) {
      this.listing();
    }
  },
  methods: {
    listing() {
      let formData = {};
      this.store.getUserProfile(formData, true);
    },
  },
};
</script>
